<template>
  <div class="ml-guru-register container mb-5">
    <div class="breadcrumd-custom"><nuxt-link to="/">Hỏi bài</nuxt-link> <img src="~/mlearn/icons/breadcrumd.svg" alt="" class="icon" /> <span class="active">Đăng ký làm gia sư</span></div>
    <div class="row">
      <div class="col-md-6 offset-md-3">
        <div class="content">
          <div class="title text-center font-weight-bold">ĐĂNG KÝ TRỞ THÀNH <br class="d-lg-none">GIA SƯ MOBILEARN</div>
          <div class="title-sub text-center text-break">Vui lòng điền đầy đủ thông tin để mobiLearn hoàn tất quá trình đăng ký</div>
          <div class="row form-register">
            <div class="col-md-4">
              <div class="form-block">
                <div class="label">Họ & tên <span>*</span></div>
                <div class="data">
                  <input placeholder="Nhập họ & tên..." v-model="form.name" />
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-block">
                <div class="label">Email <span>*</span></div>
                <div class="data">
                  <input placeholder="Email của bạn..." v-model="form.email"/>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-block">
                <div class="label">Số điện thoại <span>*</span></div>
                <div class="data">
                  <input placeholder="Số điện thoại của bạn..." v-model="form.mobile"/>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-block">
                <div class="label">Nơi công tác <span>*</span></div>
                <div class="data">
                  <input placeholder="Nơi công tác của bạn..." v-model="form.work_place"/>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-block">
                <div class="label">Tỉnh/Thành phố <span>*</span></div>
                <div class="data">
                  <el-select class="city" v-model="form.city" placeholder="Chọn...">
                    <el-option v-for="item in optionsCity" :key="item.value" :label="item.text" :value="item.value" ></el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-block">
                <div class="label">Đăng ký lớp <span>*</span> <span class="note">(Có thể chọn nhiều lớp)</span></div>
                <div class="data">
                  <el-select v-model="form.class" multiple :collapse-tags="collapseClass" placeholder="Chọn lớp học...">
                    <el-option v-for="item in classResponse" :key="item.id" :label="item.name" :value="item.id" ></el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-block">
                <div class="label">Đăng ký môn <span>*</span> <span class="note">(Có thể chọn nhiều môn)</span></div>
                <div class="data">
                  <el-select v-model="form.subject" multiple :collapse-tags="collapseCategory" placeholder="Chọn môn học...">
                    <el-option v-for="item in categoryResponse" :key="item.id" :label="item.name" :value="item.id" ></el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-block">
                <div class="label">CV <span>*</span></div>
                <div class="data">
                  <input type="file" accept=".pdf" ref="filecv" hidden @change="getFileName()" />
                  <div class="upload-cv position-relative" v-if="!fileName">
                    <span class="pointer" @click="$refs.filecv.click()"><img src="~/mlearn/icons/guru/file.svg" alt="Tải lên CV của bạn" class="position-center"/> Tải lên CV của bạn</span>
                  </div>
                  <div v-else class="upload-cv-file">{{ fileName }} <img src="~/mlearn/icons/guru/close.svg" alt="remove file" class="pointer" @click="removeCV()" /></div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-block">
                <div class="label">Ghi chú</div>
                <div class="data">
                  <textarea placeholder="Ghi chú ..." class="form-control" v-model="form.note"></textarea>
                </div>
              </div>
            </div>
            <div class="col-md-12 btn-register">
              <button @click="sendata()">Đăng ký ngay</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import axios from 'axios'
import Resource from '~/common/api/resource'
import { getToken } from '~/common/utils/auth'
const categoryResource = new Resource('category/subject')
const classResource = new Resource('category/class')
const guruRegisterResource = new Resource('guru/register')
export default {
  layout: 'mlearn',
  data () {
    return {
      optionsCity: [
        { value: 'An Giang', text: 'An Giang' },
        { value: 'Bà Rịa - Vũng Tàu', text: 'Bà Rịa - Vũng Tàu' },
        { value: 'Bạc Liêu', text: 'Bạc Liêu' },
        { value: 'Bắc Kạn', text: 'Bắc Kạn' },
        { value: 'Bắc Giang', text: 'Bắc Giang' },
        { value: 'Bắc Ninh', text: 'Bắc Ninh' },
        { value: 'Bến Tre', text: 'Bến Tre' },
        { value: 'Bình Dương', text: 'Bình Dương' },
        { value: 'Bình Định', text: 'Bình Định' },
        { value: 'Bình Thuận', text: 'Bình Thuận' },
        { value: 'Bình Phước', text: 'Bình Phước' },
        { value: 'Cà Mau', text: 'Cà Mau' },
        { value: 'Cao Bằng', text: 'Cao Bằng' },
        { value: 'Cần Thơ', text: 'Cần Thơ' },
        { value: 'Đà Nẵng', text: 'Đà Nẵng' },
        { value: 'Đắk Lắk', text: 'Đắk Lắk' },
        { value: 'Đắk Nông', text: 'Đắk Nông' },
        { value: 'Điện Biên', text: 'Điện Biên' },
        { value: 'Đồng Nai', text: 'Đồng Nai' },
        { value: 'Đồng Tháp', text: 'Đồng Tháp' },
        { value: 'Gia Lai', text: 'Gia Lai' },
        { value: 'Hà Giang', text: 'Hà Giang' },
        { value: 'Hà Nam', text: 'Hà Nam' },
        { value: 'Hà Nội', text: 'Hà Nội' },
        { value: 'Hà Tây', text: 'Hà Tây' },
        { value: 'Hà Tĩnh', text: 'Hà Tĩnh' },
        { value: 'Hải Dương', text: 'Hải Dương' },
        { value: 'Hải Phòng', text: 'Hải Phòng' },
        { value: 'Hòa Bình', text: 'Hòa Bình' },
        { value: 'Hậu Giang', text: 'Hậu Giang' },
        { value: 'Hưng Yên', text: 'Hưng Yên' },
        { value: 'Khánh Hòa', text: 'Khánh Hòa' },
        { value: 'Kiên Giang', text: 'Kiên Giang' },
        { value: 'Kon Tum', text: 'Kon Tum' },
        { value: 'Lai Châu', text: 'Lai Châu' },
        { value: 'Lào Cai', text: 'Lào Cai' },
        { value: 'Lạng Sơn', text: 'Lạng Sơn' },
        { value: 'Lâm Đồng', text: 'Lâm Đồng' },
        { value: 'Long An', text: 'Long An' },
        { value: 'Nam Định', text: 'Nam Định' },
        { value: 'Nghệ An', text: 'Nghệ An' },
        { value: 'Ninh Bình', text: 'Ninh Bình' },
        { value: 'Ninh Thuận', text: 'Ninh Thuận' },
        { value: 'Phú Thọ', text: 'Phú Thọ' },
        { value: 'Phú Yên', text: 'Phú Yên' },
        { value: 'Quảng Bình', text: 'Quảng Bình' },
        { value: 'Quảng Nam', text: 'Quảng Nam' },
        { value: 'Quảng Ngãi', text: 'Quảng Ngãi' },
        { value: 'Quảng Ninh', text: 'Quảng Ninh' },
        { value: 'Quảng Trị', text: 'Quảng Trị' },
        { value: 'Sóc Trăng', text: 'Sóc Trăng' },
        { value: 'Sơn La', text: 'Sơn La' },
        { value: 'Tây Ninh', text: 'Tây Ninh' },
        { value: 'Thái Bình', text: 'Thái Bình' },
        { value: 'Thái Nguyên', text: 'Thái Nguyên' },
        { value: 'Thanh Hóa', text: 'Thanh Hóa' },
        { value: 'Thành phố Hồ Chí Minh', text: 'Thành phố Hồ Chí Minh' },
        { value: 'Thừa Thiên Huế', text: 'Thừa Thiên Huế' },
        { value: 'Tiền Giang', text: 'Tiền Giang' },
        { value: 'Trà Vinh', text: 'Tiền Giang' },
        { value: 'Tuyên Quang', text: 'Tuyên Quang' },
        { value: 'Vĩnh Long', text: 'Vĩnh Long' },
        { value: 'Vĩnh Phúc', text: 'Vĩnh Phúc' },
        { value: 'Yên Bái', text: 'Yên Bái' }
      ],
      categoryResponse: [],
      classResponse: [],
      form: {
        name: '',
        email: '',
        mobile: '',
        work_place: '',
        city: '',
        class: [],
        subject: [],
        cv: '',
        note: ''
      },
      fileName: '',
      fileUpload: ''
    }
  },
  computed: {
    ...mapState(['user']),
    collapseCategory () {
      return this.form.subject.length > 3
    },
    collapseClass () {
      return this.form.class.length > 3
    }
  },
  async created () {
    const categoryResponse = await categoryResource.list({})
    const classResponse = await classResource.list({})
    this.categoryResponse = categoryResponse.data
    this.classResponse = classResponse.data
  },
  methods: {
    getFileName () {
      const files = this.$refs.filecv.files
      if (files.length === 0) { return }
      const file = files[0]
      this.fileName = file.name
      this.fileUpload = file
    },
    removeCV () {
      this.fileName = ''
      this.fileUpload = ''
      this.$refs.filecv.value = ''
    },
    sendata () {
      this.$notify.closeAll()
      if (this.loading) { return }
      if (!this.form.name) {
        this.$notify({
          type: 'warning',
          message: 'Bạn chưa nhập họ tên'
        })
        return
      }
      if (this.form.name.length > 255) {
        this.$notify({
          type: 'warning',
          message: 'Họ tên không quá 255 ký tự'
        })
        return
      }
      if (!this.form.email) {
        this.$notify({
          type: 'warning',
          message: 'Bạn chưa nhập email'
        })
        return
      }
      if (this.form.email.length > 255) {
        this.$notify({
          type: 'warning',
          message: 'Email không quá 255 ký tự'
        })
        return
      }
      if (!this.$helper.validEmail(this.form.email)) {
        this.$notify({
          type: 'warning',
          message: 'Email không đúng định dạng'
        })
        return
      }
      if (!this.form.mobile) {
        this.$notify({
          type: 'warning',
          message: 'Bạn chưa nhập số điện thoại'
        })
        return
      }
      if (!this.$helper.validPhone(this.form.mobile)) {
        this.$notify({
          type: 'warning',
          message: 'Số điện thoại không đúng định dạng'
        })
        return
      }
      if (!this.form.work_place) {
        this.$notify({
          type: 'warning',
          message: 'Bạn chưa nhập nơi làm việc'
        })
        return
      }
      if (this.form.work_place.length > 255) {
        this.$notify({
          type: 'warning',
          message: 'Nơi làm việc không quá 255 ký tự'
        })
        return
      }
      if (!this.form.city) {
        this.$notify({
          type: 'warning',
          message: 'Bạn chưa chọn tỉnh/thành phố'
        })
        return
      }
      if (!this.form.class.length) {
        this.$notify({
          type: 'warning',
          message: 'Bạn chưa chọn lớp có thể giảng dạy'
        })
        return
      }
      if (!this.form.subject.length) {
        this.$notify({
          type: 'warning',
          message: 'Bạn chưa chọn môn có thể giảng dạy'
        })
        return
      }
      if (this.form.note.length > 255) {
        this.$notify({
          type: 'warning',
          message: 'Ghi chú không quá 255 ký tự'
        })
        return
      }
      if (!this.fileUpload) {
        this.$notify({
          type: 'warning',
          message: 'Bạn chưa chọn file CV cần upload'
        })
        return
      }
      const formData = new FormData()
      formData.append('file', this.fileUpload)
      const token = getToken()
      const config = {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }
      this.loading = true
      axios.post(process.env.NUXT_ENV_BASE_API_UPLOAD + '/upload/user/cv', formData, config)
        .then((res) => {
          this.form.cv = res.data.data[0].fileDownloadUri
        })
        .then(() => {
          this.saveData()
        })
        .finally(() => {
          this.loading = false
          this.$refs.filecv.value = ''
        })
    },
    saveData () {
      const classSend = this.classResponse.filter((item) => {
        return this.form.class.includes(item.id)
      })
      const subjectSend = this.categoryResponse.filter((item) => {
        return this.form.subject.includes(item.id)
      })
      const data = Object.assign({}, this.form)
      data.class = classSend
      data.subject = subjectSend
      guruRegisterResource.store(data)
        .then((res) => {
          if (res.status === 200) {
            this.$notify({
              type: 'success',
              message: 'Đăng ký thành công. Bên Quản trị sẽ liên hệ lại với bạn trong thời gian sớm nhất'
            })
            this.reset()
          }
        })
    },
    reset () {
      this.form = {
        name: '',
        email: '',
        mobile: '',
        work_place: '',
        city: '',
        class: [],
        subject: [],
        cv: '',
        note: ''
      }
      this.fileName = ''
      this.fileUpload = ''
    }
  }
}
</script>

<style lang="scss">
  .ml-guru-register{
    .title{
      font-size: 24px;
      margin-bottom:10px;
      @media (max-width:576px) {
        font-size: 18px;
      }
    }
    .title-sub{
      color: #65676B;
      font-size: 14px;
      @media (max-width:576px) {
        font-size: 12px;
      }
    }
    .form-register{
      .form-block {
        padding-top: 24px;
        @media (max-width:576px) {
          padding-top: 16px;
        }
        .label{
          font-size: 12px;
          margin-bottom: 4px;
          span{
            color:#e95050;
          }
          .note{
            font-size: 12px;
            color: #65676B;
          }
        }
        .data{
          color: #cbcdd7;
          font-size: 20px;
          @media (max-width:576px) {
            font-size: 16px;
          }
          textarea{
            border: none;
            width: 100%;
            font-size: 14px;
            border: 1px solid #CED4DA !important;
            border-radius: 8px;
            resize: none;
            height: 70px;
            padding:14px 16px 16px;
            @media (max-width:576px) {
              height: 50px;
              padding:8px 10px 10px;
            }
            &::placeholder{
              color: #A4A4A5;
            }
          }
          input{
            width: 100%;
            font-weight: 500;
            font-size: 14px;
            border: 1px solid #CED4DA !important;
            border-radius: 8px;
            padding:14px 16px 16px;
            @media (max-width:576px) {
              padding:8px 10px 10px;
            }
            &::placeholder{
              color: #A4A4A5;
            }
          }
          .el-select{
            width: 100%;
            .el-select__tags{
              margin-left: 10px;
              max-width: 100% !important;
              .el-tag{
                height:auto;
                background: var(--primary);
                border-radius: 4px;
                color:var(--white);
                margin-top: 0;
                margin-bottom: 0;
                display: flex;
                align-items: center;
                padding: 10px 12px;
                @media (max-width:576px) {
                  padding: 6px 9px;
                }
                &:first-child{
                  margin-left: 0;
                }
                &:last-child{
                  margin-right: 0;
                }
                i{
                  &.el-icon-close{
                    background: var(--white);
                    color:var(--primary);
                    font-weight: bold;
                  }
                }
              }
            }
            .el-input{
              .el-input__inner{
                height: auto !important;
                line-height: unset;
              }
              .el-input__suffix{
                display: none;
              }
            }
          }
          .upload-cv, .upload-cv-file{
            text-align: center;
            font-size: 14px;
            color: var(--primary);
            font-weight: 500;
            background: var(--white);
            border: 1px solid #CED4DA;
            border-radius: 8px;
            padding:16px;
            @media (max-width:576px) {
              padding:8px 10px;
            }
            img {
              left: 16px;
              @media (max-width:576px) {
                left: 10px;
              }
            }
          }
        }
      }
      .btn-register{
        text-align: center;
        margin-top:22px;
        @media (max-width:576px) {
          margin-top:18px;
        }
        button{
          background: var(--primary);
          border-radius: 5px;
          font-weight: 500;
          color: var(--white);
          font-size: 16px;
          padding:10px 40px;
          @media (max-width:576px) {
            font-size: 14px;
            padding:8px 30px;
            width:100%;
          }
        }
      }
    }
  }
</style>
