<template>
  <div class="ml-detail-topic mb-5" v-if="item">
    <div class="container">
      <div class="breadcrumd-custom"><nuxt-link to="/khoa-hoc">Khóa học </nuxt-link> <img src="~/mlearn/icons/breadcrumd.svg" alt="" class="icon" /> <span class="active">{{item.name}}</span></div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-10 offset-lg-1">
          <div class="topic-title">{{item.name}}</div>
          <div v-if="item.description" class="sub-title">Giới thiệu</div>
        </div>
      </div>
    </div>
    <div class="container container-mobile">
      <div class="row">
        <div class="col-lg-10 offset-lg-1">
          <template v-if="item.description">
            <div class="block-data">
              <div class="description text-break" v-html="item.description"></div>
              <!-- <div class="description text-break" :class="showViewMore && !viewMore ? 'display-html display-3-line' : ''" v-html="item.description"></div> -->
              <!-- <template v-if="showViewMore">
                <div class="view-more">
                  <span class="text-primary pointer" v-if="!viewMore" @click="viewMore = true">Xem thêm</span>
                  <span class="text-primary pointer" v-else @click="viewMore = false">Thu gọn</span>
                </div>
              </template> -->
              <div class="list-tags flex-wrap d-flex" v-if="item.tags.length">
                <span v-for="(tag, ind) in item.tags" :key="ind" class="tag-item">{{ tag }}</span>
              </div>
            </div>
          </template>
          <div class="container container-mobile">
            <div class="sub-content">
              <!-- Không có collections -->
              <template v-if="!item.collections.length">
                <Files :list-data="item.files"/>
              </template>
              <!-- có collections -->
              <template v-else>
                <Collections :list-data="item.collections"/>
              </template>
            </div>
          </div>
          <div class="container container-mobile">
            <div class="block-data" v-if="documentList.length">
              <div class="sub-title mt-0">Tài liệu liên quan</div>
              <VueSlickCarousel v-bind="carouselSettings">
                <DocumentItem v-for="item in documentList" :key="item.id" :item="item" :action="false"/>
                <template #prevArrow>
                  <ArrowLeft />
                </template>
                <template #nextArrow>
                  <ArrowRight />
                </template>
              </VueSlickCarousel>
            </div>
            <div class="block-data mt-3" v-if="quizList.length">
              <div class="sub-title mt-0">Bộ đề liên quan</div>
              <VueSlickCarousel v-bind="carouselSettings">
                <QuizItem v-for="item in quizList" :key="item.id" :item="item" :action="false"/>
                <template #prevArrow>
                  <ArrowLeft />
                </template>
                <template #nextArrow>
                  <ArrowRight />
                </template>
              </VueSlickCarousel>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PlaylistNotify :flag-notify="flagNotify" :text-notify="textNotify" @hide="flagNotify = false" />
  </div>
</template>

<script>
import cookieParser from 'cookieparser'
import Files from '~/components/mlearn/video/files.vue'
import Collections from '~/components/mlearn/video/collections.vue'
import PlaylistNotify from '~/components/mlearn/playlist/notify.vue'
import DocumentItem from '~/components/mlearn/document/item.vue'
import QuizItem from '~/components/mlearn/quiz/item.vue'
import Resource from '~/common/api/resource'
const topicSlugResource = new Resource('library/topic/getBySlug')
const documentResource = new Resource('library/file/involve')
const quizResource = new Resource('library/exam/involve')
export default {
  components: {
    Files,
    Collections,
    PlaylistNotify,
    DocumentItem,
    QuizItem
  },
  layout: 'mlearn',
  async asyncData ({ isDev, route, store, env, params, query, req, res, redirect, error }) {
    let token = ''
    if (req && req.headers && req.headers.cookie) {
      const requestCookies = cookieParser.parse(req.headers.cookie)
      token = requestCookies.access_token || ''
    }
    const tmp1 = await topicSlugResource.list({ slug: params.slug }, token)
      .catch(() => {
        return error({ statusCode: 404, title: 'Chuyên đề không tồn tại', message: 'Chuyên đề đã bị xóa hoặc bị báo cáo vi phạm.' })
      })
    if (!tmp1) {
      return error({ statusCode: 404, title: 'Chuyên đề không tồn tại', message: 'Chuyên đề đã bị xóa hoặc bị báo cáo vi phạm.' })
    }
    const item = tmp1.data
    return {
      item,
      viewMore: false,
      showViewMore: false,
      carouselSettings: {
        arrows: true,
        dots: false,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1920,
            settings: {
              slidesToShow: 4
            }
          },
          {
            breakpoint: 1366,
            settings: {
              slidesToShow: 3.8
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2.5
            }
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1.2,
              arrows: false
            }
          }
        ]
      },
      documentList: [],
      quizList: [],
      textNotify: '',
      flagNotify: false
    }
  },
  mounted () {
    this.loadDocument()
    this.loadQuiz()
    this.checkShowMore()
  },
  methods: {
    handerRedirect (redirect = []) {
      let data = null
      if (redirect.length) {
        data = {}
        redirect.forEach((ele) => {
          data[ele] = this.item[ele]
        })
      }
      this.$store.dispatch('setStoreRedirect', data)
      this.$router.push('/khoa-hoc')
    },
    checkShowMore () {
      const innerWidth = window.innerWidth
      let limit = 380
      switch (innerWidth) {
        case 375:
          limit = 100
          break
        case 992:
          limit = 200
          break
        case 1024:
          limit = 300
          break
        case 1366:
          limit = 400
          break
        default:
          limit = 500
          break
      }
      this.showViewMore = false
      if (this.item.description.length > limit) {
        this.showViewMore = true
      }
    },
    showNotify (text) {
      this.flagNotify = false
      this.textNotify = text
      this.flagNotify = true
    },
    async loadDocument () {
      const query = {
        class_id: this.item.class_id,
        category_id: this.item.category_id,
        type: 'doc',
        limit: 10
      }
      const { data } = await documentResource.list(query)
      this.documentList = data
    },
    async loadQuiz () {
      const query = {
        class_id: this.item.class_id,
        category_id: this.item.category_id,
        type: 'doc',
        limit: 10
      }
      const { data } = await quizResource.list(query)
      this.quizList = data
    }
  }
}
</script>

<style lang="scss" scoped>
  .ml-detail-topic{
    .topic-title{
      font-weight: bold;
      font-size: 28px;
      @media (max-width:1366px) {
        font-size: 20px;
      }
      @media (max-width:576px) {
        font-size: 18px;
      }
    }
    .block-data{
      background: var(--white);
      border-radius: 8px;
      padding:16px;
      border: 1px solid #CED4DA;
      @media (max-width:1366px) {
        border-radius: 5px;
        padding:12px;
      }
      @media (max-width:576px) {
        border-radius: 0;
        padding:10px;
        border: 0;
      }
      .description{
        white-space: break-spaces;
        font-size: 16px;
        @media (max-width:576px) {
          font-size: 14px;
        }
      }
      .view-more{
        padding-top:8px;
        font-size: 14px;
        font-weight: 500;
      }
      .list-tags{
        padding-top: 5px;
        .tag-item{
          background: #EBEBEB;
          border-radius: 14px;
          font-size: 14px;
          color: #75777B;
          padding:6px 12px;
          margin-right:6px;
          margin-top:7px;
          &:last-child{
            margin-right: 0;
          }
        }
      }
    }
  }
</style>
<style lang="scss">
  .ml-detail-topic{
    .sub-title{
      font-weight: 600;
      font-size: 20px;
      margin-top: 24px;
      margin-bottom: 10px;
      @media (max-width:1366px) {
        font-size: 18px;
        margin-top: 10px;
        margin-bottom: 8px;
      }
      @media (max-width:576px) {
        font-size: 16px;
        margin-top: 10px;
        margin-bottom: 5px;
      }
    }
    .slick-slider{
      .slick-list{
        margin: 0 -9px;
        .slick-track{
          .slick-slide{
            padding: 10px 9px;
            @media (max-width:1366px) {
              padding: 10px 5px;
            }
            @media (max-width:576px) {
              padding: 10px 4px;
            }
          }
        }
      }
    }
  }
</style>
