<template>
  <div class="container container-mobile mb-5 ml-huong-dan-su-dung">
    <div class="content">
      <div class="support-title">Hướng dẫn sử dụng</div>
      <div class="description text-justify text-break pt-3">
        <div class="title font-weight-bold">1. Đối tượng sử dụng dịch vụ</div>
        <div class="description text-justify text-break pt-3">
          Dịch vụ được cung cấp cho tất cả các thuê bao đang hoạt động 02 chiều trên mạng Viettel
        </div>
        <div class="title font-weight-bold">2. Điều kiện sử dụng dịch vụ</div>
        <div class="description text-justify text-break pt-3">
          Để sử dụng dịch vụ trên kênh wap/website/app: Thuê bao phải đang sử dụng dịch vụ Mobile Internet hoặc 3G bình thường.
        </div>
        <div class="title font-weight-bold">3. Hướng dẫn sử dụng</div>
        <div class="description text-justify text-break pt-3">
          <table style="width:100%">
            <tr>
              <td style="text-align: center; font-weight: bold; width: 80px">TT</td>
              <td style="font-weight: bold">Loại cước</td>
              <td style="font-weight: bold">Mã gói</td>
              <td style="font-weight: bold">Giá cước (VNĐ)</td>
              <td style="font-weight: bold">Thời hạn sử dụng</td>
            </tr>
            <tr>
              <td style="text-align: center; font-weight: bold">1</td>
              <td style="font-weight: bold" colspan="4">Cước thuê bao</td>
            </tr>
            <tr>
              <td style="text-align: center">1.1</td>
              <td>Gói tháng</td>
              <td>CL</td>
              <td>30.000</td>
              <td>01 tháng</td>
            </tr>
            <tr>
              <td style="text-align: center; font-weight: bold">2</td>
              <td style="font-weight: bold">Cước data (Nếu có)</td>
              <td style="font-weight: bold" colspan="3">Tính cước theo quy định</td>
            </tr>
            <tr>
              <td style="text-align: center; font-weight: bold">3</td>
              <td style="font-weight: bold">Cước nhắn tin đến đầu số 9063</td>
              <td style="font-weight: bold" colspan="3">Miễn phí</td>
            </tr>
          </table>
          - Đăng ký soạn: <strong>DK2 CL</strong> hoặc <strong>DK2</strong> gửi 9063<br>
          - Hủy đăng ký dịch vụ: Soạn HUY CL gửi 9063.<br>
          - Xem hướng dẫn: Soạn HDL gửi 9063.<br>
          - Lấy lại Mật khẩu: Soạn MKL gửi 9063.<br>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Resource from '~/common/api/resource'
const supportPaymentResource = new Resource('price/support-payment')

export default {
  layout: 'mlearn',
  async asyncData ({ isDev, route, store, env, params, query, req, res, redirect, error }) {
    const activeIndex = parseInt(query.i) || 0
    const { data } = await supportPaymentResource.list()
    return {
      support: data,
      activeIndex
    }
  },
  computed: {
    contentTab1 () {
      if (!this.support.length) {
        return 'Chưa có nội dung'
      }
      const data = this.support.find(item => item.type === 'HUONGDANTHANHTOAN')
      return data?.content || 'Chưa có nội dung'
    },
    contentTab2 () {
      if (!this.support.length) {
        return 'Chưa có nội dung'
      }
      const data = this.support.find(item => item.type === 'HUONGDANSUDUNGGOISUB')
      return data?.content || 'Chưa có nội dung'
    },
    contentTab3 () {
      if (!this.support.length) {
        return 'Chưa có nội dung'
      }
      const data = this.support.find(item => item.type === 'HUONGDANHOCGIASU')
      return data?.content || 'Chưa có nội dung'
    }
  }
}
</script>
<style lang="scss" scoped>
.ml-huong-dan-su-dung{
  .content{
    background: var(--white);
    box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
    border-radius: 8px;
    padding:24px;
    margin-top:20px;
    @media (max-width:576px) {
      box-shadow: none;
      border-radius: 0;
      padding:16px;
      margin-top:10px;
    }
    @media (max-width:1366px) {
      padding:18px;
      margin-top:12px;
    }
    .support-title{
      font-weight: bold;
      text-transform: uppercase;
      font-size: 24px;
      @media (max-width:576px) {
        font-size: 16px;
      }
      @media (max-width:1366px) {
        font-size: 18px;
      }
    }
    .tabs{
      .nav-tabs{
        border-bottom: 1px solid #EBEBEB;
        @media (max-width:576px) {
          display: flex;
          white-space: nowrap;
          flex-wrap: nowrap;
          overflow-x: auto;
          overflow-y: hidden;
        }
        .nav-item{
          margin-right: 30px;
          @media (max-width:576px) {
            margin-right: 16px;
          }
          @media (max-width:1366px) {
            margin-right: 18px;
          }
          &:last-child{
            margin-right: 0;
          }
          .nav-link{
            font-weight: 500;
            border:none;
            color: #65676B;
            background: transparent;
            font-size: 16px;
            padding: 0.5rem 0;
            @media (max-width:576px) {
              font-size: 14px;
              padding: 0.35rem 0;
            }
            @media (max-width:1366px) {
              font-size: 15px;
            }
            &.active{
              color: var(--primary);
              font-weight: bold;
              position: relative;
              &::before{
                content: '';
                background: var(--primary);
                position: absolute;
                bottom:0;
                left:50%;
                transform: translateX(-50%);
                height: 4px;
                width:70px;
                @media (max-width:576px) {
                  height: 3px;
                  width:50px;
                }
                @media (max-width:1366px) {
                  height: 3px;
                  width: 60px;
                }
              }
            }
          }
        }
      }
    }
  }
  .min-height{
    min-height: 60vh;
    @media (max-width:576px) {
      min-height: 50vh;
    }
  }
}
.ml-huong-dan-su-dung{
  .form-content{
    background: var(--white);
    box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
    border-radius: 8px;
    padding:24px;
    @media (max-width:576px) {
      box-shadow: none;
      border-radius: 0;
      padding:16px;
    }
    .header{
      border-bottom: 3px solid #EBEBEB;
      @media (max-width:576px) {}
      span{
        font-weight: 500;
        cursor: pointer;
        color: #65676B;
        font-size: 16px;
        margin-right: 40px;
        padding-bottom: 5px;
        @media (max-width:576px) {
          font-size: 14px;
          margin-right: 20px;
        }
        &:last-child{
          margin-right: 0;
        }
        &.active{
          position: relative;
          &::after{
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            background: var(--primary);
            height: 3px;
            width:20px;
            @media (max-width:576px) {
              height: 2px;
              width:30px;
            }
          }
        }
      }
    }
    .content{
      > h4 {
        font-weight: bold;
        margin: 0;
        font-size: 24px;
        padding:40px 0 24px;
        @media (max-width:576px) {
          font-size: 16px;
          padding:20px 0 16px;
        }
      }
      .form-contact{
        background: #FAFAFA;
        border: 1px solid #EBEBEB;
        border-radius: 16px;
        padding:24px;
        margin-top: 40px;
        @media (max-width:576px) {
          border-radius: 10px;
          padding:16px;
          margin-top: 20px;
        }
        > h4 {
          font-weight: bold;
          margin-bottom: 0;
          font-size: 24px;
          padding:0 0 24px;
          @media (max-width:576px) {
            font-size: 16px;
            padding:0 0 16px;
          }
        }
        .group-item{
          margin-bottom: 16px;
          @media (max-width:576px) {
            margin-bottom: 10px;
          }
          .label{
            font-size: 12px;
          }
          input, textarea{
            background: var(--white);
            border: 1px solid #CED4DA !important;
            border-radius: 8px;
            width: 100%;
            font-size: 14px;
            resize: none;
            padding:10px 16px;
            @media (max-width:576px) {
              padding:8px 10px;
            }
            &::placeholder{
              opacity: .7;
            }
          }
          button{
            background: var(--primary);
            border-radius: 5px;
            color: var(--white);
            padding:10px 40px;
            @media (max-width:576px) {
              padding:8px 30px;
            }
          }
        }
        .form-data{
          margin-bottom: 16px;
          @media (max-width:576px) {
            margin-bottom: 10px;
          }
          .icon{
            margin-right: 16px;
            @media (max-width:576px) {
              margin-right: 10px;
            }
          }
          .title{
            font-size: 16px;
            opacity: 0.7;
            @media (max-width:576px) {
              font-size: 14px;
            }
          }
          .description{
            font-weight: 500;
            font-size: 16px;
            @media (max-width:576px) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  table td {
    border: 1px solid black;
    padding: 0 15px;
  }
}
</style>
