<template>
  <div class="ml-goi-cuoc container mb-5">
    <div class="breadcrumd-custom"><span class="active">Gói cước</span></div>
    <div class="row list-sub">
      <div class="col-lg-3"></div>
      <div class="col-lg-3 pb-3" v-for="sub in listSub" :key="sub.id">
        <div class="sub-item">
          <div class="title font-weight-bold text-lg-center">{{ sub.name }} <span class="type d-lg-none">({{ sub.title }})</span></div>
          <div class="price text-lg-center">{{ $helper.formatMoney(sub.price, '.') }}đ <span>/{{sub.unit}}</span></div>
          <div class="type text-center d-none d-lg-block">{{ sub.title }}</div>
          <div class="other">{{sub.description}}</div>
          <div class="action text-right">
            <!-- <template v-if="sub.register">
              <div class="note text-center">
                Thời hạn sử dụng {{ sub.expired_date | parseTime('{h}:{i}') }} ngày {{ sub.expired_date | parseTime('{d}/{m}/{y}') }}
              </div>
              <div class="note text-center">
                Gói cước tự động gia hạn
              </div>
            </template> -->
            <button @click="showMessage(sub)">{{ sub.register ? 'Hủy' : 'Đăng ký' }}</button>
            <template v-if="!sub.register">
              <div class="note text-center">
                Gói cước chỉ cung cấp cho Thuê bao di động Viettel
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <b-modal hide-footer hide-header id="modal-notify-sub" centered v-model="showModal">
      <div class="title">{{isRegister ? 'Bạn đang sử dụng gói tháng của mobiLearn' : 'Bạn chưa sử dụng gói tháng của mobiLearn'}}</div>
      <div class="description">{{ message }}</div>
      <div class="action text-center">
        <button @click="showModal = false">Đóng</button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Resource from '~/common/api/resource'
const subResource = new Resource('price/sub')
export default {
  layout: 'mlearn',
  data () {
    return {
      showModal: false,
      listSub: [],
      message: '',
      isRegister: false
    }
  },
  computed: {
    isMobile () {
      return this.$store.state.isMobile
    }
  },
  created () {
    this.loadSub()
  },
  methods: {
    showMessage (sub) {
      if (this.isMobile) {
        window.open(`sms:${sub.service_number}?&body=${sub.register ? sub.unreg_syntax : sub.reg_syntax}`, '_blank')
        return
      }
      this.showModal = true
      this.isRegister = sub.register
      // this.message = `Để xác nhận ${sub.register ? 'hủy' : 'đăng ký'} gói ${sub.unit}, mời Quý khách soạn tin ${sub.register ? sub.unreg_syntax : sub.reg_syntax} gửi ${sub.service_number} xác nhận ${sub.register ? 'hủy' : 'đăng ký'} DV. Phí DV ${this.$helper.formatMoney(sub.price, '.') + 'đồng/' + sub.unit.toLowerCase()}, ${sub.title.toLowerCase()}.`
      if (sub.register) {
        this.message = `Để hủy dịch vụ bằng cách soạn ${sub.unreg_syntax} gửi ${sub.service_number} (${sub.unit.toLowerCase()})`
      } else {
        this.message = `Để sử dụng dịch vụ soạn ${sub.reg_syntax} gửi ${sub.service_number} (${this.$helper.formatMoney(sub.price, '.')}đ/${sub.unit.toLowerCase()})`
      }
    },
    async loadSub () {
      const { data } = await subResource.list()
      this.listSub = data
    }
  }
}
</script>

<style lang="scss" scoped>
  .ml-goi-cuoc{
    .list-sub{
      .sub-item{
        position: relative;
        background: var(--white);
        box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
        border-radius: 8px;
        padding:24px;
        @media (max-width:576px) {
          padding:16px;
        }
        &::after{
          content: '';
          position: absolute;
          background: var(--primary);
          box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
          border-radius: 0px 0px 8px 8px;
          height: 8px;
          width:100%;
          bottom: 0;
          left: 0;
          @media (max-width:576px) {
            border-radius: 8px 0px 0px 8px;
            height: 100%;
            width: 8px;
            bottom: 0;
            left: 0;
          }
        }
        .title{
          font-size: 16px;
          .type{
            font-size: 12px;
            color: #65676B;
          }
        }
        .price{
          font-weight: 600;
          color: var(--primary);
          font-size: 26px;
          @media (max-width:576px) {
            padding-top: 16px;
          }
          span{
            font-size: 22px;
            @media (max-width:576px) {}
          }
        }
        .type{
          font-size: 12px;
          color: #65676B;
        }
        .other{
          color: #65676B;
          white-space: break-spaces;
          line-height: 200%;
          font-size: 16px;
          margin-top: 10px;
          @media (max-width:576px) {
            font-size: 13px;
            margin-top: 8px;
          }
        }
        .action{
          margin-top: 40px;
          @media (max-width:576px) {
            margin-top: 16px;
          }
          .note{
            color: #65676B;
            font-size: 14px;
            margin: 5px 0;
          }
          button{
            background: var(--primary);
            box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
            border-radius: 8px;
            font-weight: 500;
            font-size: 16px;
            text-align: center;
            color: var(--white);
            width: 100%;
            padding:14px 0;
            @media (max-width:1366px) {
              padding:14px 0;
            }
            @media (max-width:576px) {
              padding:10px 30px;
              width: auto;
            }
            &:hover{
              box-shadow: 0px 3px 6px rgba(202, 202, 202, 0.34);
            }
          }
        }
      }
    }
  }
</style>
<style lang="scss">
  #modal-notify-sub{
    .modal-dialog{
      max-width: 585px;
      @media (max-width:576px) {}
      .modal-content{
        .modal-body{
          padding:24px;
          text-align: center;
          @media (max-width:1366px) {
            padding:18px;
          }
          @media (max-width:576px) {
            padding:16px;
          }
          .title{
            font-weight: bold;
            font-size: 24px;
            @media (max-width:1366px) {
              font-size: 18px;
            }
            @media (max-width:576px) {
              font-size: 16px;
            }
          }
          .description {
            margin-top: 10px;
            font-weight: 500;
            font-size: 14px;
            color: #65676B;
            @media (max-width:576px) {}
          }
          .action{
            padding-top: 24px;
            button{
              background: var(--primary);
              border-radius: 22.5px;
              font-weight: 500;
              font-size: 16px;
              color: #FFFFFF;
              padding:14px 60px;
            }
          }
        }
      }
    }
  }
</style>
