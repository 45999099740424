<template>
  <div class="ml-detail-khoa-hoc mb-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-2 d-none d-lg-block">
          <MenuLeft @selectItem="findCategory"/>
        </div>
        <div class="col-lg-10">
          <div class="d-flex align-items-center justify-content-between">
            <ClassSelect class="d-none d-lg-block" placeholder="Lớp" @findClass="findClass" :library-show="true"/>
            <ButtonAddQuestion class="d-none d-lg-block mr-auto ml-3"/>
            <FormSearch :value="keyword" @enter="handerSearch" />
          </div>
          <div class="d-lg-none text-right pt-3 pb-2" :class="{'active': showFilter}" @click="openFilter">
            <span class="mr-2">Lọc</span>
            <span v-html="require('~/assets/mlearn/icons/home/filter.svg?raw')" />
          </div>
          <div v-if="keyword" class="text-info text-break mt-3">
            {{ query.total }} kết quả tìm kiếm cho từ khóa "<b>{{ keyword }}</b>"
          </div>
          <div class="container container-mobile">
            <div class="topic mt-3">
              <ViewMode @mode="changeMode" :title="keyword ? 'Video' : (type === 'nang-cao' ? 'Khóa học chuyên sâu' : 'Khóa học cơ bản')" />
              <div class="row list-item">
                <template v-if="keyword">
                  <VideoItem v-for="item in listData" :key="item.id" :class="{'topic-item-list col-6 col-md-4 col-xl-3': viewList, 'topic-item-detail col-sm-12': !viewList}" :view-list="viewList" :item="item"/>
                </template>
                <template v-else>
                  <VideoTopicItem v-for="item in listData" :key="item.id" :class="{'topic-item-list col-6 col-md-4 col-xl-3': viewList, 'topic-item-detail col-sm-12': !viewList}" :view-list="viewList" :item="item" @showNotify="showNotify"/>
                </template>
              </div>
              <NoData v-if="!listData.length && !loading" text="Nội dung đang cập nhật"/>
              <Pagination class="mt-3" :total="query.total" :value="query.page" @change="loadData" :limit="query.limit" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <PlaylistNotify :flag-notify="flagNotify" :text-notify="textNotify" @hide="flagNotify = false" />
    <MobileFilter title="Lọc câu hỏi" :show-filter="showFilter" @applyFilter="applyFilter" @closeFilter="closeFilter">
      <div class="block-data">
        <div class="title text-uppercase">Môn</div>
        <div class="row list-item">
          <div class="col-4 item">
            <div class="block-item" :class="{'active': !query.category_id}" @click="setQuery('category_id', '')">Tất cả</div>
          </div>
          <div class="col-4 item" v-for="item in listCategory" :key="'filter' + item.id">
            <div class="block-item" :class="{'active': query.category_id === item.id}" @click="setQuery('category_id', item.id)">{{item.name}}</div>
          </div>
        </div>
      </div>
      <div class="block-data">
        <div class="title text-uppercase">Lớp</div>
        <div class="row list-item">
          <div class="col-4 item">
            <div class="block-item" :class="{'active': !query.class_id}" @click="setQuery('class_id', '')">Tất cả</div>
          </div>
          <div class="col-4 item" v-for="item in listClass" :key="'filter' + item.id">
            <div class="block-item" :class="{'active': query.class_id === item.id}" @click="setQuery('class_id', item.id)">{{item.name}}</div>
          </div>
        </div>
      </div>
    </MobileFilter>
  </div>
</template>

<script>
import cookieParser from 'cookieparser'
import MenuLeft from '~/components/mlearn/layout/menu/left.vue'
import FormSearch from '~/components/mlearn/select/form-search.vue'
import ClassSelect from '~/components/mlearn/select/class.vue'
import ButtonAddQuestion from '~/components/mlearn/question/button.vue'
import VideoTopicItem from '~/components/mlearn/video/topic.vue'
import VideoItem from '~/components/mlearn/video/item.vue'
import Pagination from '~/components/mlearn/pagination/index'
import ViewMode from '~/components/mlearn/view-mode/index.vue'
import PlaylistNotify from '~/components/mlearn/playlist/notify.vue'
import MobileFilter from '~/components/mlearn/mobile/filter.vue'
import Resource from '~/common/api/resource'
const categoryResource = new Resource('category/subject')
const classResource = new Resource('category/class')

const hotTopicResource = new Resource('library/topic/hot')
const topicResource = new Resource('library/topic')
const fileResource = new Resource('library/file')

export default {
  components: {
    MenuLeft,
    FormSearch,
    ButtonAddQuestion,
    ClassSelect,
    VideoItem,
    VideoTopicItem,
    Pagination,
    ViewMode,
    MobileFilter,
    PlaylistNotify
  },
  layout: 'mlearn',
  async asyncData ({ isDev, route, store, env, params, req, res, redirect, error }) {
    const type = params.slug
    if (!['nang-cao', 'co-ban'].includes(type)) {
      return redirect('/khoa-hoc')
    }
    let token = ''
    if (req && req.headers && req.headers.cookie) {
      const requestCookies = cookieParser.parse(req.headers.cookie)
      token = requestCookies.access_token || ''
    }
    const hotTopic = await hotTopicResource.list({ limit: 20 }, token)
    const query = {
      category_id: '',
      class_id: '',
      type: 'video',
      keyword: '',
      page: 1,
      limit: 12,
      total: 0,
      classify: type === 'nang-cao' ? 'ADVANCED' : 'BASIC'
    }
    const storeData = store.state.storeRedirect
    if (storeData) {
      query.category_id = storeData.category_id || ''
      query.class_id = storeData.class_id || ''
    }
    const topics = await topicResource.list(query, token)
    query.total = topics.total || 0
    return {
      type,
      hotTopic: hotTopic.data,
      listData: topics.data,
      query,
      keyword: '',
      viewList: true,
      flagNotify: false,
      textNotify: '',
      showFilter: false,
      listCategory: [],
      listClass: []
    }
  },
  data () {
    return {
      carouselSettings: {
        arrows: true,
        dots: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2
            }
          }
        ]
      }
    }
  },
  async created () {
    const { data: listClass } = await classResource.list()
    this.listClass = listClass
    const { data: listCategory } = await categoryResource.list()
    this.listCategory = listCategory
    this.$store.dispatch('setStoreRedirect', null)
  },
  methods: {
    changeMode (mode) {
      this.viewList = mode
    },
    reset () {
      this.listData = []
      this.query = {
        category_id: '',
        class_id: '',
        type: 'video',
        keyword: '',
        page: 1,
        limit: 12,
        total: 0
      }
      this.keyword = ''
    },
    findClass (classId) {
      this.query.class_id = classId
      this.query.page = 1
      this.query.total = 0
      this.loadData()
    },
    findCategory (categoryId) {
      this.query.category_id = categoryId
      this.query.page = 1
      this.query.total = 0
      this.loadData()
    },
    async loadData (page = 1) {
      this.query.page = page
      if (this.loading) { return }
      this.loading = true
      let data
      if (!this.keyword) {
        data = await topicResource.list(this.query)
      } else {
        data = await fileResource.list(this.query)
      }
      this.listData = data.data || []
      this.query.total = data.total || 0
      this.loading = false
    },
    handerSearch (keyword) {
      this.query.keyword = keyword
      this.keyword = keyword
      this.loadData()
    },
    showNotify (text) {
      this.flagNotify = false
      this.textNotify = text
      this.flagNotify = true
    },
    setQuery (field, value) {
      this.query[field] = value
    },
    openFilter () {
      this.showFilter = true
    },
    closeFilter () {
      this.showFilter = false
    },
    applyFilter () {
      this.closeFilter()
      this.loadData()
    }
  }
}
</script>

<style lang="scss" scoped>
  .ml-detail-khoa-hoc {
    .topic {
      background: var(--white);
      box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
      border-radius: 12px;
      padding: 20px 20px 32px 20px;
      @media (max-width:1366px) {
        padding: 18px;
      }
      @media (max-width:576px) {
        padding: 16px;
      }
      .list-item{
        .topic-item-list {
          padding-top: 18px;
          @media (max-width:1366px) {
            padding-top: 15px;
          }
          @media (max-width:576px) {
            padding-top: 10px;
          }
        }
        .topic-item-detail{
          border-bottom: 1px solid #EBEBEB;
          padding-top:12px;
          padding-bottom:12px;
          @media (max-width:1366px) {
            padding-top:10px;
            padding-bottom:10px;
          }
          @media (max-width:576px) {
            padding-top:8px;
            padding-bottom:8px;
          }
          &:first-child{
            padding-top: 20px;
            @media (max-width:1366px) {
              padding-top: 15px;
            }
            @media (max-width:576px) {
              padding-top: 10px;
            }
          }
          &:last-child{
            border-bottom: 0;
          }
        }
      }
    }
  }
</style>
