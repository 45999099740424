<template>
  <div class="container ml-doi-mat-khau mb-5">
    <div class="breadcrumd-custom"><span class="active">Đổi mật khẩu</span></div>
    <div class="row">
      <div class="col-lg-8 offset-lg-2">
        <div class="content" v-if="!showForgot">
          <div class="title font-weight-bold">Đổi mật khẩu</div>
          <div class="group-form">
            <div class="note text-danger font-weight-bold pt-2" v-if="disableInput">Chức năng không dành cho các tài khoản đăng nhập bằng facebook, google</div>
            <div class="group-item">
              <div class="label">Nhập mật khẩu cũ</div>
              <div class="position-relative">
                <input :type="!showPassOld ? 'text' : 'password'" class="w-100" placeholder="Mật khẩu cũ" v-model="form.curentPassword" :disabled="disableInput">
                <img :src="showPassOld ? iconHide : iconShow" alt="Pass" class="icon position-center pointer" @click="showPassOld = !showPassOld">
              </div>
            </div>
            <div class="group-item">
              <div class="label">Nhập mật khẩu mới</div>
              <div class="position-relative">
                <input :type="!showPassNew ? 'text' : 'password'" class="w-100" placeholder="Mật khẩu mới" v-model="form.newPassword" :disabled="disableInput">
                <img :src="showPassNew ? iconHide : iconShow" alt="Pass" class="icon position-center pointer" @click="showPassNew = !showPassNew">
              </div>
            </div>
            <div v-if="!disableInput">
              <span class="forgot text-primary pointer" @click="showForgot = true">Quên mật khẩu</span>
            </div>
            <div class="line"></div>
            <div class="action">
              <button :disabled="disableInput" @click="changePass">Lưu</button>
            </div>
          </div>
        </div>
        <div class="content" v-else>
          <div class="title font-weight-bold">Quên mật khẩu</div>
          <div class="group-form-sub">
            <div class="description">Để lấy mật khẩu, soạn MKL gửi 9063</div>
            <div class="action">
              <button @click="showForgot = false">Đổi mật khẩu</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cookieParser from 'cookieparser'
import { setToken } from '~/common/utils/auth'
import IconShow from '~/assets/mlearn/icons/change-pass/show.svg'
import IconHide from '~/assets/mlearn/icons/change-pass/hide.svg'
import Resource from '~/common/api/resource'
const userResource = new Resource('profile/user')
const userPassWordAResource = new Resource('user/password')
export default {
  layout: 'mlearn',
  middleware: ['authenticated'],
  async asyncData ({ isDev, route, store, env, params, query, req, res, redirect, error }) {
    let token = ''
    if (req && req.headers && req.headers.cookie) {
      const requestCookies = cookieParser.parse(req.headers.cookie)
      token = requestCookies.access_token || ''
    }
    await userResource.list({}, token)
    const disableInput = false
    // const { data } = await userResource.list({}, token)
    // if (data.signupType === 0 || data.signupType === 4) { // login form
    //   disableInput = false
    // } else { // login facebook, google
    //   disableInput = true
    // }
    return {
      disableInput,
      iconShow: IconShow,
      iconHide: IconHide,
      showPassOld: true,
      showPassNew: true,
      showForgot: false,
      form: {
        curentPassword: '',
        newPassword: '',
        rePassword: ''
      },
      loading: false
    }
  },
  methods: {
    changePass () {
      this.$notify.closeAll()
      if (this.loading) { return }
      if (this.disableInput) {
        this.$notify({
          type: 'warning',
          message: 'Chức năng không dành cho tài khoản đăng nhập bằng facebook, google'
        })
        return
      }
      this.form.rePassword = this.form.newPassword
      if (!this.form.curentPassword) {
        this.$notify({
          type: 'error',
          message: 'Bạn chưa nhập mật khẩu cũ'
        })
        return
      }
      if (!this.form.newPassword) {
        this.$notify({
          type: 'error',
          message: 'Bạn chưa nhập mật khẩu mới'
        })
        return
      }
      if (this.form.newPassword !== this.form.rePassword) {
        this.$notify({
          type: 'error',
          message: 'Mật khẩu mới và xác nhận mật khẩu phải trùng nhau'
        })
        return
      }
      if (this.form.newPassword.length < 8 || this.form.newPassword.length > 20) {
        this.$notify({
          type: 'error',
          message: 'Mật khẩu dài từ 8 - 20 ký tự'
        })
        return
      }
      if (this.$helper.emoji(this.form.newPassword)) {
        this.$notify({
          type: 'warning',
          message: 'Mật khẩu gồm chữ cái, số và hoặc kí tự đặc biệt'
        })
        return
      }
      this.loading = true
      userPassWordAResource
        .put(this.form)
        .then((response) => {
          if (response.status === 200) {
            this.form.curentPassword = ''
            this.form.newPassword = ''
            this.form.rePassword = ''
            this.$notify({
              type: 'success',
              message: 'Cập nhật dữ liệu thành công'
            })
            const token = response.data.accessToken
            setToken(token)
            setTimeout(() => {
              location.href = '/'
            }, 500)
          }
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            message: error.response.data.message
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .ml-doi-mat-khau{
    .content{
      background: var(--white);
      border: 1px solid #CED4DA;
      border-radius: 8px;
      padding:24px;
      @media (max-width:1366px) {
        padding:18px;
      }
      @media (max-width:576px) {
        padding:16px;
      }
      .title{
        border-bottom: 1px solid #EBEBEB;
        font-size: 24px;
        @media (max-width:1366px) {
          font-size: 18px;
        }
        @media (max-width:576px) {
          font-size: 16px;
        }
      }
      .group-form{
        width: 355px;
        max-width: 100%;
        margin:auto;
        @media (max-width:576px) {
          width: 310px;
        }
        .group-item{
          padding-top: 24px;
          @media (max-width:1366px) {
            padding-top: 18px;
          }
          @media (max-width:576px) {
            padding-top: 16px;
          }
          .label{
            font-size: 12px;
            margin-bottom: 4px;
          }
          input{
            font-weight: 500;
            font-size: 14px;
            background: var(--white);
            border: 1px solid #CED4DA !important;
            border-radius: 8px;
            padding:14px 40px 14px 16px;
            @media (max-width:1366px) {
              padding:12px 25px 12px 14px;
            }
            @media (max-width:576px) {
              padding:10px 20px 10px 11px;
            }
            &::placeholder{
              color: #EBEBEB;
            }
          }
          .icon{
            right: 15px;
          }
        }
        .forgot{
          padding-top: 20px;
          font-weight: 500;
          font-size: 14px;
          @media (max-width:576px) {
            padding-top: 10px;
          }
        }
        .line{
          margin-top: 12px;
          padding-top: 12px;
          border-top: 1px solid #EBEBEB;
          @media (max-width:576px) {}
        }
        .action{
          button{
            background: var(--primary);
            border-radius: 5px;
            font-weight: 500;
            font-size: 14px;
            text-align: center;
            color: var(--white);
            width:120px;
            padding:10px 0;
            @media (max-width:576px) {
              width:auto;
              padding:10px 45px;
            }
          }
        }
      }
      .group-form-sub{
        .description{
          font-weight: 500;
          font-size: 14px;
          margin: 10px 0 20px;
          @media (max-width:576px) {}
        }
        .action{
          button{
            background: var(--primary);
            border-radius: 5px;
            width:120px;
            font-weight: 500;
            font-size: 14px;
            text-align: center;
            color: var(--white);
            padding:10px 0;
            @media (max-width:576px) {}
          }
        }
      }
    }
  }
</style>
